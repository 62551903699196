<template>
<CRow>
    <CCol sm="12">
        <CCard style="min-height: 120px;">        
            <CCardHeader class="pt-3 pb-0">    
                <h1 class="pb-2">Welcome to Uprave</h1>                
            </CCardHeader>
            <CCardBody>
                <p>Get more customer feedbacks, reviews and email opt-ins.</p>
                <h2>What's new?</h2>                
                <h4>Preview mode</h4>
                <p>Open the landing page in preview mode to see it even when it is hidden: <a :href="siteUrl" target='_blank'>{{siteUrl}}</a></p>
                <p>Feedbacks entered in preview mode will be marked as such and will not added to the list of shipments.</p>
                <p>This is for testing content and functionality only</p>
            </CCardBody>
        </CCard>
    </CCol>
</CRow>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['merchant', 'siteUrl'])
    }
}
</script>

<style scoped>
img.logo {
    width:100px; 
    height:auto; 
    max-height: 30px; 
    object-fit: contain; 
    object-position: right top;
}
</style>
